import { ScaleLinear } from 'd3-scale';
import { useMemo } from 'react';
import { LodFactor } from 'types/LodFactor';

export function useTimeLodFactor(timeScale: ScaleLinear<number, number>) {
    const timeDomain = useMemo(() => timeScale.domain(), [timeScale]);

    const timeDelta = timeDomain[1] - timeDomain[0];

    if (timeDelta > 160) {
        return LodFactor.SIXTEENTH;
    }
    if (timeDelta > 80) {
        return LodFactor.EIGHTH;
    }
    if (timeDelta > 40) {
        return LodFactor.QUARTER;
    }
    if (timeDelta > 20) {
        return LodFactor.HALF;
    }

    return LodFactor.ORIGINAL;
}
