import React, { useCallback, useContext } from 'react';
import VideoStateContext from 'App/VideoStateContext';
import { MdPause, MdPlayArrow } from 'react-icons/md';
import { Button, Grid, Slider } from '@material-ui/core';
import VideoFileContext from 'App/VideoFileContext';
import { timeFormatter } from 'lib/string-format';
import VideoSeekContext from 'App/VideoSeekContext';

interface Props {}

const VideoControls: React.FunctionComponent<Props> = ({}: Props) => {
    const { videoFileInfo } = useContext(VideoFileContext);
    const { isPlaying, toggleIsPlaying, playbackPosition } = useContext(VideoStateContext);
    const { seekTo } = useContext(VideoSeekContext);

    const handlePlayPause = useCallback(() => {
        toggleIsPlaying();
    }, [toggleIsPlaying]);

    const handleSliderChange = useCallback(
        (event: React.ChangeEvent<unknown>, value: number | number[]) => {
            seekTo(value as number);
        },
        [seekTo]
    );

    const keyDownCatcher = useCallback((event: React.KeyboardEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item>
                <Button onKeyDown={keyDownCatcher} variant="contained" onClick={handlePlayPause}>
                    {isPlaying ? <MdPause /> : <MdPlayArrow />}
                </Button>
            </Grid>
            <Grid item>{timeFormatter(playbackPosition, 2)}&nbsp;s</Grid>
            <Grid item xs>
                <Slider
                    min={0}
                    step={0.1}
                    max={videoFileInfo.duration}
                    value={playbackPosition}
                    onChange={handleSliderChange}
                    aria-labelledby="continuous-slider"
                />
            </Grid>
            <Grid item>{timeFormatter(videoFileInfo.duration, 2)}&nbsp;s</Grid>
        </Grid>
    );
};

export default VideoControls;
