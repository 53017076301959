import React, { useMemo } from 'react';
import { ScaleLinear } from 'd3-scale';
import { Group } from '@visx/group';
import EatEventsContext from 'App/EatEventsContext';
import EventSequenceComponent from 'App/EditorPanel/Event/EventSequenceComponent';
import { useFrameIdxLodFactor } from 'hooks/useFrameIdxLodFactor';
import { LodFactor } from 'types/LodFactor';
import SingleEventComponent from 'App/EditorPanel/Event/SingleEventComponent';

interface Props {
    height: number;
    frameScale: ScaleLinear<number, number>;
}

const EventStream: React.FunctionComponent<Props> = ({ frameScale, height }: Props) => {
    const { eatEvents, eatEventSequences, eventTypeColorScale, eventTypeColorScaleDark, eventTypeColorScaleLightest } =
        React.useContext(EatEventsContext);

    const clippedEvents = useMemo(
        () =>
            eatEvents.filter(
                (eatEvent) => eatEvent.idxFrame > frameScale.domain()[0] && eatEvent.idxFrame < frameScale.domain()[1]
            ),
        [eatEvents, frameScale]
    );

    const clippedEventSequences = useMemo(
        () =>
            eatEventSequences.filter(
                (eatEventSequence) =>
                    eatEventSequence.idxEndFrame > frameScale.domain()[0] &&
                    eatEventSequence.idxStartFrame < frameScale.domain()[1]
            ),
        [eatEventSequences, frameScale]
    );

    const rectHeight = height / 5;

    const lodFactor = useFrameIdxLodFactor(frameScale);

    return (
        <Group id={'eventStream'} transform={`translate(0 ${height / 10})`}>
            {clippedEventSequences.map((eatEventSequence) => (
                <EventSequenceComponent
                    key={'sequence_' + eatEventSequence.idxStartFrame}
                    eatEventSequence={eatEventSequence}
                    x={frameScale(eatEventSequence.idxStartFrame)}
                    y={rectHeight + eatEventSequence.eventType * (rectHeight + 10)}
                    width={frameScale(eatEventSequence.idxEndFrame) - frameScale(eatEventSequence.idxStartFrame)}
                    height={20}
                    fill={eventTypeColorScale(eatEventSequence.eventType)}
                    stroke={eventTypeColorScaleLightest(eatEventSequence.eventType)}
                />
            ))}
            {lodFactor < LodFactor.HALF &&
                clippedEvents.map((eatEvent) => (
                    <SingleEventComponent
                        key={'event_' + eatEvent.idxFrame}
                        eatEvent={eatEvent}
                        cx={frameScale(eatEvent.idxFrame)}
                        cy={rectHeight + eatEvent.eventType * (rectHeight + 10)}
                        r={3}
                        fill={eventTypeColorScale(eatEvent.eventType)}
                        stroke={eventTypeColorScaleDark(eatEvent.eventType)}
                    />
                ))}
        </Group>
    );
};

export default React.memo(EventStream);
