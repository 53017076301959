import { scaleLinear } from '@visx/scale';
import React, { useMemo } from 'react';
import { Area } from '@visx/shape';
import WaveformData from 'waveform-data';
import _ from 'lodash';
import { ScaleLinear } from 'd3-scale';
import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { useTimeLodFactor } from 'hooks/useTimeLodFactor';

interface Props {
    height: number;
    timeScale: ScaleLinear<number, number>;
    waveformData: WaveformData;
    label?: string;
}

const VideoWaveform: React.FunctionComponent<Props> = ({ timeScale, waveformData, height, label }: Props) => {
    return (
        <Group>
            <VideoWaveformContent timeScale={timeScale} waveformData={waveformData} height={height} />
            {label && <VideoWaveformLabel x={timeScale(0) - 16} y={height / 2} label={label} />}
        </Group>
    );
};

interface LabelProps {
    x: number;
    y: number;
    label: string;
    padding?: number;
}

const VideoWaveformLabel: React.FunctionComponent<LabelProps> = ({ x, y, label, padding = 3 }: LabelProps) => {
    return (
        <Text x={x + padding} y={y} dominantBaseline={'central'} textAnchor={'end'} className={'no-pointer'}>
            {label}
        </Text>
    );
};

interface VideoWaveformProps {
    height: number;
    waveformData: WaveformData;
    timeScale: ScaleLinear<number, number>;
}

const VideoWaveformContent: React.FunctionComponent<VideoWaveformProps> = ({
    timeScale,
    waveformData,
    height,
}: VideoWaveformProps) => {
    const chMin = useMemo(() => waveformData.channel(0).min_array(), [waveformData]);
    const chMax = useMemo(() => waveformData.channel(0).max_array(), [waveformData]);

    let data = useMemo(
        () =>
            _.zip(
                chMin.map((v, i) => i / waveformData.pixels_per_second),
                chMin,
                chMax
            ) as [number, number, number][],
        [chMin, chMax, waveformData.pixels_per_second]
    );

    const lodFactor = useTimeLodFactor(timeScale);

    // Reduce level of detail when zoomed out.
    data = useMemo(() => data.filter((_, i) => i % lodFactor === 0), [data, lodFactor]);

    // Filter data points that lie out of viewport.
    const viewport = useMemo(() => timeScale.range(), [timeScale]);
    data = useMemo(
        () => data.filter(([t]) => timeScale(t) >= viewport[0] && timeScale(t) <= viewport[1]),
        [data, timeScale, viewport]
    );

    const y = useMemo(
        () =>
            scaleLinear<number>()
                .domain([Math.min(...chMin), Math.max(...chMax)])
                .rangeRound([0, height]),
        [chMax, chMin, height]
    );

    return (
        <>
            <Area<[number, number, number]>
                fill={'#1a4057'}
                data={data}
                x={(d, i) => timeScale(d[0])}
                y0={(d) => y(d[1])}
                y1={(d) => y(d[2])}
            />
        </>
    );
};

export default VideoWaveform;
