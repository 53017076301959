import React from 'react';

interface IVideoSeekContext {
    seekPosition: number;
    seekTo: (position: number) => void;
    seekDelta: (increment: number) => void;
    overrideSeekTo: (position: number) => void;
    clearOverride: () => void;
}

const defaultContext: IVideoSeekContext = {
    seekPosition: 0,
    seekTo: () => {
        // Initial value. Replaced by context provider.
    },
    seekDelta: () => {
        // Initial value. Replaced by context provider.
    },
    overrideSeekTo: () => {
        // Initial value. Replaced by context provider.
    },
    clearOverride: () => {
        // Initial value. Replaced by context provider.
    },
};

const VideoSeekContext = React.createContext(defaultContext);

export default VideoSeekContext;
