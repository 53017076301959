import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { HiddenInput } from 'App/HiddenInput';
import { MdFileDownload, MdFileUpload } from 'react-icons/md';
import { csvFormat, csvParse } from 'd3-dsv';
import { numberArrayDecoder } from 'lib/json-decoders';
import { JsonDecoder } from 'ts.data.json';
import EatEventsContext from 'App/EatEventsContext';
import { downloadFile, splitFileName } from 'lib/files';
import moment from 'moment';

interface Props {}

const EventFileManager: React.FunctionComponent<Props> = ({}: Props) => {
    const [prevEventsFileName, setPrevEventsFileName] = React.useState<string | undefined>(undefined);
    const { eatEvents, setEatEvents } = React.useContext(EatEventsContext);

    const handleEventJSONDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        const baseName = prevEventsFileName ? splitFileName(prevEventsFileName)[0] : 'events';

        downloadFile({
            data: csvFormat(
                eatEvents.map((e) => ({
                    idx_frame: e.idxFrame,
                    events_prob: `[${e.eventsProbability.join(', ')}]`,
                    event_type: e.eventType,
                }))
            ),
            fileName: baseName + '_' + moment().format('YYYY-MM-DD_HH-m-s') + '.csv',
            fileType: 'text/csv',
        });
    };

    const handleEventCsvUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length === 1) {
            const file = event.target.files[0];
            const objectURL = URL.createObjectURL(file);

            fetch(objectURL)
                .then((response) => {
                    window.URL.revokeObjectURL(objectURL);
                    return response.text();
                })
                .then((textContent) =>
                    csvParse(textContent, (d, i) => {
                        const idxFrame = JsonDecoder.number.decode(JSON.parse(d['idx_frame'] ?? ''));
                        const eventsProbability = numberArrayDecoder.decode(JSON.parse(d['events_prob'] ?? ''));
                        const eventType = JsonDecoder.number.decode(JSON.parse(d['event_type'] ?? ''));

                        return {
                            idxFrame: idxFrame.isOk() ? idxFrame.value : -1,
                            eventsProbability: eventsProbability.isOk() ? eventsProbability.value : [],
                            eventType: eventType.isOk() ? eventType.value : -1,
                        };
                    })
                )
                .then((eatEvents) => {
                    // console.log('Eating events loaded. First 10 rows:', eatEvents.slice(0, 10));
                    setEatEvents(eatEvents);
                    setPrevEventsFileName(file.name);
                });
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                Loaded: {prevEventsFileName}
            </Grid>
            <Grid item xs={12}>
                Event file:
                <HiddenInput
                    type="file"
                    multiple={false}
                    accept={'.csv'}
                    onChange={handleEventCsvUpload}
                    id="event-upload"
                />
                <label htmlFor="event-upload">
                    <IconButton component="span">
                        <MdFileUpload />
                    </IconButton>
                </label>
                <IconButton onClick={handleEventJSONDownload}>
                    <MdFileDownload />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default EventFileManager;
