import React, { useCallback, useEffect, useState } from 'react';
import { Grid, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';
import { getAudioWaveformData, prepareVideoFileInfo } from 'lib/video-analysis';
import { HiddenInput } from 'App/HiddenInput';
import { MdFileUpload } from 'react-icons/md';
import { PartialVideoFileInfo, VideoFileInfo } from 'types/VideoFileInfo';
import produce from 'immer';

interface Props {
    onVideoFileInfoChange?: (videoFileInfo: VideoFileInfo) => void;
}

const FileManager: React.FunctionComponent<Props> = ({ onVideoFileInfoChange }: Props) => {
    const [partialVideoFileInfo, setPartialVideoFileInfo] = useState<PartialVideoFileInfo>();
    const [frameRate, setFrameRate] = useState<number>(30);

    useEffect(() => {
        partialVideoFileInfo &&
            onVideoFileInfoChange &&
            onVideoFileInfoChange({
                frameRate,
                ...partialVideoFileInfo,
            });
    }, [onVideoFileInfoChange, partialVideoFileInfo, frameRate]);

    const handleVideoUpload = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files != null && event.target.files.length === 1) {
            const file = event.target.files[0];
            prepareVideoFileInfo(file)
                // We first want to get the general video information.
                .then((partialVideoInfo) => {
                    setPartialVideoFileInfo(partialVideoInfo);
                })
                .then(() => {
                    // Then we want to get the audio waveform data...
                    getAudioWaveformData(file).then((audioWaveformData) => {
                        setPartialVideoFileInfo(
                            (prevState) =>
                                prevState &&
                                produce(prevState, (draft) => {
                                    draft.waveform = audioWaveformData;
                                })
                        );
                    });
                });
        }
    }, []);

    const handleFrameRateChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        setFrameRate(e.target.value as number);
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                Loaded: {partialVideoFileInfo?.name}
            </Grid>
            <Grid item xs={6}>
                Video file:
                <HiddenInput
                    type="file"
                    multiple={false}
                    accept={'video/*'}
                    onChange={handleVideoUpload}
                    id="video-upload"
                />
                <label htmlFor="video-upload">
                    <IconButton component="span">
                        <MdFileUpload />
                    </IconButton>
                </label>
            </Grid>
            <Grid item xs={6}>
                <InputLabel id="video-frame-rate-select-label">FPS</InputLabel>
                <Select
                    labelId="video-frame-rate-select-label"
                    id="video-frame-rate-select"
                    value={frameRate}
                    label="Frame Rate (FPS)"
                    onChange={handleFrameRateChange}
                >
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={23.976}>23.976 (NTSC Film)</MenuItem>
                    <MenuItem value={24}>24</MenuItem>
                    <MenuItem value={25}>25 (PAL Film/Video)</MenuItem>
                    <MenuItem value={29.97}>29.97 (NTSC Video)</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={59.94}>59.94</MenuItem>
                    <MenuItem value={60}>60</MenuItem>
                    <MenuItem value={120}>120</MenuItem>
                </Select>
            </Grid>
        </Grid>
    );
};

export default FileManager;
