import WaveformData from 'waveform-data';

export interface PartialVideoFileInfo {
    name: string;
    objectURL: string;
    duration: number;
    waveform?: WaveformData;
}

export interface VideoFileInfo extends PartialVideoFileInfo {
    frameRate: number;
}

export const VIDEO_FILE_INFO_DEFAULT: VideoFileInfo = {
    name: '',
    objectURL: '',
    duration: 0,
    frameRate: 0,
};
