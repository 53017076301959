import React, { useCallback, useContext } from 'react';
import { EatEvent } from 'types/EatEvent';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import VideoSeekContext from 'App/VideoSeekContext';
import VideoFileContext from 'App/VideoFileContext';
import EatEventSelectionContext from 'App/EatEventSelectionContext';

interface Props {
    eatEvent: EatEvent;
    cx: number;
    cy: number;
    r: number;
    fill?: string;
    stroke?: string;
}

const SingleEventComponent: React.FunctionComponent<Props> = ({ eatEvent, cx, cy, r, fill, stroke }: Props) => {
    const { overrideSeekTo, clearOverride } = useContext(VideoSeekContext);
    const { videoFileInfo } = useContext(VideoFileContext);
    const { selectedEventsFrameIdx } = useContext(EatEventSelectionContext);

    const { tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip();

    const { TooltipInPortal } = useTooltipInPortal();

    const handleMouseMove = useCallback(
        (event: React.MouseEvent<SVGCircleElement>) => {
            showTooltip({
                tooltipLeft: event.clientX,
                tooltipTop: event.clientY,
            });
        },
        [showTooltip]
    );

    const handleMouseOver = useCallback(() => {
        overrideSeekTo(eatEvent.idxFrame / videoFileInfo.frameRate);
    }, [eatEvent.idxFrame, overrideSeekTo, videoFileInfo.frameRate]);

    const handleMouseOut = useCallback(() => {
        hideTooltip();
        clearOverride();
    }, [clearOverride, hideTooltip]);

    const isSelected = selectedEventsFrameIdx?.includes(eatEvent.idxFrame);

    return (
        <svg>
            <circle
                onMouseOver={handleMouseOver}
                onMouseMove={handleMouseMove}
                onMouseOut={handleMouseOut}
                cx={cx}
                cy={cy}
                r={r}
                fill={fill}
                stroke={stroke}
                strokeWidth={isSelected ? 3 : 1}
            />

            {tooltipOpen && (
                <TooltipInPortal top={tooltipTop} left={tooltipLeft}>
                    Frame: <strong>{eatEvent.idxFrame}</strong>
                    <br />
                    Event Type: <strong>{eatEvent.eventType}</strong>
                    <br />
                    Probabilities: <strong>[{eatEvent.eventsProbability.map((p) => p.toFixed(2)).join(', ')}]</strong>
                </TooltipInPortal>
            )}
        </svg>
    );
};

export default SingleEventComponent;
