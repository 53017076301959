import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import EatEventsContext from 'App/EatEventsContext';
import { BsAlignEnd, BsCheckSquare } from 'react-icons/bs';
import { AiOutlineFieldNumber } from 'react-icons/ai';
import VideoSeekContext from 'App/VideoSeekContext';
import VideoFileContext from 'App/VideoFileContext';
import EatEventSelectionContext from 'App/EatEventSelectionContext';

const useStyles = makeStyles({
    tableContainer: {
        maxHeight: 400,
        overflowX: 'hidden',
    },
    tableCell: {
        border: 'unset',
    },
});

interface Props {}

const EventTable: React.FunctionComponent<Props> = (props: Props) => {
    const { seekTo } = useContext(VideoSeekContext);
    const { videoFileInfo } = useContext(VideoFileContext);
    const {
        eatEventSequences: rawEatEventSequences,
        eventTypeColorScale,
        eventTypeColorScaleLighter,
    } = useContext(EatEventsContext);
    const { selectedEventSequence, setSelectedEventSequence } = useContext(EatEventSelectionContext);

    const [activeRowRef, setActiveRowRef] = React.useState<HTMLTableRowElement | null>(null);

    const eatEventSequences = useMemo(
        () => rawEatEventSequences.filter((eatEventSequence) => eatEventSequence.eventType > 0),
        [rawEatEventSequences]
    );

    const classes = useStyles();

    useEffect(() => {
        activeRowRef?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }, [activeRowRef]);

    const onClickHandler = useCallback(
        (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
            const targetEventIdx = Number(event.currentTarget.getAttribute('data-event-sequence-idx'));

            if (targetEventIdx) {
                const targetEventSequence = eatEventSequences[targetEventIdx];
                setSelectedEventSequence(targetEventSequence);
                seekTo(targetEventSequence.idxStartFrame / videoFileInfo.frameRate);
            }
        },
        [eatEventSequences, seekTo, setSelectedEventSequence, videoFileInfo.frameRate]
    );

    return (
        <TableContainer className={classes.tableContainer}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            <BsAlignEnd />
                        </TableCell>
                        <TableCell align="center">
                            <AiOutlineFieldNumber />
                        </TableCell>
                        <TableCell align="center">
                            <BsCheckSquare />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {eatEventSequences.map((eatEventSequence, eventIdx) => {
                        const isSelected = selectedEventSequence === eatEventSequence;
                        const rowColor = eventTypeColorScaleLighter(eatEventSequence.eventType);
                        const rowBorder = isSelected
                            ? `2px solid ${eventTypeColorScale(eatEventSequence.eventType)}`
                            : undefined;

                        return (
                            <TableRow
                                ref={isSelected ? (r) => setActiveRowRef(r) : undefined}
                                className={'no-select'}
                                key={eatEventSequence.idxStartFrame}
                                data-event-sequence-idx={eventIdx}
                                onClick={onClickHandler}
                                style={{ backgroundColor: rowColor, border: rowBorder }}
                            >
                                <TableCell className={classes.tableCell} align="center">
                                    {eatEventSequence.idxStartFrame}
                                </TableCell>
                                <TableCell className={classes.tableCell} align="center">
                                    {eatEventSequence.idxEndFrame}
                                </TableCell>
                                <TableCell className={classes.tableCell} align="center">
                                    {eatEventSequence.eventType}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default EventTable;
