import React, { useCallback, useMemo } from 'react';
import VideoStateContext from 'App/VideoStateContext';

interface Props {}

const VideoStateContextProvider = ({ children }: React.PropsWithChildren<Props>) => {
    const [playbackPosition, setPlaybackPosition] = React.useState<number>(0);
    const [isPlaying, setIsPlaying] = React.useState<boolean>(false);

    const toggleIsPlaying = useCallback(() => {
        setIsPlaying((prevState) => !prevState);
    }, []);

    const pausePlayback = useCallback(() => {
        setIsPlaying(false);
    }, []);

    const startPlayback = useCallback(() => {
        setIsPlaying(false);
    }, []);

    // Memoize the value object itself, so it doesn't lead to unnecessary re-renders.
    const providerValueMemo = useMemo(
        () => ({
            playbackPosition,
            isPlaying,
            setPlaybackPosition,
            setIsPlaying,
            toggleIsPlaying,
            pausePlayback,
            startPlayback,
        }),
        [isPlaying, pausePlayback, playbackPosition, startPlayback, toggleIsPlaying]
    );

    return <VideoStateContext.Provider value={providerValueMemo}>{children}</VideoStateContext.Provider>;
};

export default VideoStateContextProvider;
