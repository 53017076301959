import React, { useState } from 'react';
import EatEventSelectionContext from 'App/EatEventSelectionContext';
import { EatEventSequence } from 'types/EatEventSequence';

interface Props {}

const EatEventSelectionContextProvider = ({ children }: React.PropsWithChildren<Props>) => {
    const [selectedEventsFrameIdx, setSelectedEventsFrameIdx] = useState<number[] | undefined>(undefined);
    const [selectedEventSequence, setSelectedEventSequence] = useState<EatEventSequence | undefined>(undefined);

    return (
        <EatEventSelectionContext.Provider
            value={{
                selectedEventsFrameIdx,
                setSelectedEventsFrameIdx,
                selectedEventSequence,
                setSelectedEventSequence,
            }}
        >
            {children}
        </EatEventSelectionContext.Provider>
    );
};

export default EatEventSelectionContextProvider;
