import React from 'react';
import VideoFileContext from 'App/VideoFileContext';
import { VideoFileInfo } from 'types/VideoFileInfo';

interface Props {
    videoFileInfo: VideoFileInfo;
}

const VideoFileContextProvider = ({ children, videoFileInfo }: React.PropsWithChildren<Props>) => {
    return <VideoFileContext.Provider value={{ videoFileInfo }}>{children}</VideoFileContext.Provider>;
};

export default VideoFileContextProvider;
