import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import VideoFileContext from 'App/VideoFileContext';
import { scaleLinear } from '@visx/scale';
import { localPoint } from '@visx/event';
import VideoStateContext from 'App/VideoStateContext';
import EditorPanelContent from 'App/EditorPanel/EditorPanelContent';
import VideoSeekContext from 'App/VideoSeekContext';
import EatEventSelectionContext from 'App/EatEventSelectionContext';
import EatEventsContext from 'App/EatEventsContext';

const ZOOM_IN_FACTOR = 1.1;
const ZOOM_OUT_FACTOR = 1 / ZOOM_IN_FACTOR;

interface Props {
    width: number;
    height: number;
}

const EditorPanelSVG: React.FunctionComponent<Props> = ({ width, height }: Props) => {
    const { videoFileInfo } = useContext(VideoFileContext);
    const { playbackPosition } = useContext(VideoStateContext);
    const { seekTo } = useContext(VideoSeekContext);
    const { setSelectedEventsFrameIdx } = useContext(EatEventSelectionContext);
    const { eatEvents } = useContext(EatEventsContext);

    const [editingRegion, setEditingRegion] = React.useState<{ from: number; to: number }>({ from: 0, to: 0 });
    const [dragXStart, setDragXStart] = React.useState<number | null>(null);
    const [selectXStart, setSelectXStart] = React.useState<number | null>(null);
    const didDrag = React.useRef<boolean>(false);

    useEffect(() => {
        setEditingRegion({
            from: 0,
            to: videoFileInfo.duration,
        });
    }, [videoFileInfo.duration]);

    const timeScale = useMemo(
        () => scaleLinear<number>().domain([editingRegion.from, editingRegion.to]).range([0, width]),
        [editingRegion.from, editingRegion.to, width]
    );
    const frameScale = useMemo(
        () =>
            scaleLinear<number>()
                .domain([editingRegion.from * videoFileInfo.frameRate, editingRegion.to * videoFileInfo.frameRate])
                .range([0, width]),
        [editingRegion.from, editingRegion.to, videoFileInfo.frameRate, width]
    );

    const onSvgClick = useCallback(
        (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            if (didDrag.current) {
                return;
            }

            const posOnSvg = localPoint(event) ?? { x: 0, y: 0 };
            const playPosition = timeScale.invert(posOnSvg.x);
            seekTo(playPosition);
        },
        [timeScale, seekTo]
    );

    const onSvgScroll = useCallback(
        (event: React.WheelEvent<SVGSVGElement>) => {
            const currentInterval = editingRegion.to - editingRegion.from;
            const zoomFactor = event.deltaY > 0 ? ZOOM_IN_FACTOR : ZOOM_OUT_FACTOR;

            const zoomScale = scaleLinear<number>()
                .domain([0, currentInterval])
                .range([0, currentInterval * zoomFactor]);

            // Scale editing region by zoom factor around the current position.
            const newEditingRegion = {
                from: zoomScale(editingRegion.from - playbackPosition) + playbackPosition,
                to: zoomScale(editingRegion.to - playbackPosition) + playbackPosition,
            };

            setEditingRegion(newEditingRegion);
        },
        [editingRegion.from, editingRegion.to, playbackPosition]
    );

    const onSvgMouseDown = useCallback((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        const posOnSvg = localPoint(event) ?? { x: 0, y: 0 };

        // If shift key is pressed, start region select.
        if (event.shiftKey) {
            setSelectXStart(posOnSvg.x);
            return;
        }

        // Otherwise, start drag.
        didDrag.current = false;
        setDragXStart(posOnSvg.x);
    }, []);

    const onSvgMouseMove = useCallback(
        (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            const posOnSvg = localPoint(event) ?? { x: 0, y: 0 };

            if (dragXStart !== null) {
                const dragXEnd = posOnSvg.x;

                if (Math.abs(dragXEnd - dragXStart) > 0) {
                    didDrag.current = true;
                }

                const dragTimeDelta = timeScale.invert(dragXStart) - timeScale.invert(dragXEnd);

                const newEditingRegion = {
                    from: editingRegion.from + dragTimeDelta,
                    to: editingRegion.to + dragTimeDelta,
                };

                setEditingRegion(newEditingRegion);
                setDragXStart(dragXEnd);
                return;
            }

            if (selectXStart !== null) {
                const selectXEnd = posOnSvg.x;
                const startSelectionFrame = frameScale.invert(selectXStart);
                const endSelectionFrame = frameScale.invert(selectXEnd);

                const selectedEvents = eatEvents
                    .map((eatEvent) => eatEvent.idxFrame)
                    .filter((idxFrame) => idxFrame >= startSelectionFrame && idxFrame <= endSelectionFrame);

                setSelectedEventsFrameIdx(selectedEvents);
            }
        },
        [
            dragXStart,
            selectXStart,
            timeScale,
            editingRegion.from,
            editingRegion.to,
            frameScale,
            eatEvents,
            setSelectedEventsFrameIdx,
        ]
    );

    const onSvgMouseUp = useCallback(
        (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            setDragXStart(null);

            if (selectXStart !== null) {
                setSelectXStart(null);
            } else {
                setSelectedEventsFrameIdx(undefined);
            }
        },
        [selectXStart, setSelectedEventsFrameIdx]
    );

    return (
        <svg
            width={width}
            height={height}
            style={{ backgroundColor: '#e0e0e0' }}
            onClick={onSvgClick}
            onWheel={onSvgScroll}
            onMouseDown={onSvgMouseDown}
            onMouseMove={onSvgMouseMove}
            onMouseUp={onSvgMouseUp}
            className={'no-select'}
        >
            <EditorPanelContent
                timeScale={timeScale}
                frameScale={frameScale}
                width={width}
                height={height}
                maxVideoDuration={videoFileInfo.duration}
            />
        </svg>
    );
};

export default EditorPanelSVG;
