import React from 'react';

interface IVideoStateContext {
    playbackPosition: number;
    setPlaybackPosition: (position: number) => void;
    isPlaying: boolean;
    setIsPlaying: (isPlaying: boolean) => void;
    toggleIsPlaying: () => void;
    pausePlayback: () => void;
    startPlayback: () => void;
}

const defaultContext: IVideoStateContext = {
    playbackPosition: 0,
    isPlaying: false,
    setPlaybackPosition: () => {
        // Initial value. Replaced by context provider.
    },
    setIsPlaying: () => {
        // Initial value. Replaced by context provider.
    },
    toggleIsPlaying: () => {
        // Initial value. Replaced by context provider.
    },
    pausePlayback: () => {
        // Initial value. Replaced by context provider.
    },
    startPlayback: () => {
        // Initial value. Replaced by context provider.
    },
};

const VideoStateContext = React.createContext(defaultContext);

export default VideoStateContext;
