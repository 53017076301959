import React from 'react';
import VideoPlayerInstance from 'App/VideoPlayer/VideoPlayerInstance';
import VideoControls from 'App/VideoPlayer/VideoControls';
import { Grid } from '@material-ui/core';

interface Props {}

const VideoPlayer: React.FunctionComponent<Props> = ({}: Props) => {
    return (
        <Grid container spacing={2}>
            <VideoPlayerInstance isControllingInstance={true} />
            <Grid item xs={12}>
                <VideoControls />
            </Grid>
        </Grid>
    );
};

export default VideoPlayer;
