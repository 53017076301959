import React, { useCallback, useMemo } from 'react';
import VideoSeekContext from 'App/VideoSeekContext';

interface Props {}

const VideoSeekContextProvider = ({ children }: React.PropsWithChildren<Props>) => {
    const [seekPosition, setSeekPosition] = React.useState<number>(0);
    const [overrideSeekPosition, setOverrideSeekPosition] = React.useState<number | undefined>(undefined);

    const seekDelta = useCallback((increment: number) => {
        setSeekPosition((prevPosition) => prevPosition + increment);
    }, []);

    const overrideSeekTo = useCallback((position: number) => setOverrideSeekPosition(position), []);
    const clearOverride = useCallback(() => setOverrideSeekPosition(undefined), []);

    // Memoize the value object itself, so it doesn't lead to unnecessary re-renders.
    const providerValueMemo = useMemo(
        () => ({
            seekPosition: overrideSeekPosition ?? seekPosition,
            seekTo: setSeekPosition,
            seekDelta,
            overrideSeekTo,
            clearOverride,
        }),
        [clearOverride, overrideSeekPosition, overrideSeekTo, seekDelta, seekPosition]
    );

    return <VideoSeekContext.Provider value={providerValueMemo}>{children}</VideoSeekContext.Provider>;
};

export default VideoSeekContextProvider;
