import React, { FunctionComponent, useContext } from 'react';
import EatEventSelectionContext from 'App/EatEventSelectionContext';
import styled from 'styled-components';
import { MenuItem, Select } from '@material-ui/core';
import EatEventsContext from 'App/EatEventsContext';

const EventTypeChangePanelContainer = styled.div`
    display: block;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.9);
`;

interface Props {}

const EventTypeChangePanel: FunctionComponent<Props> = (props) => {
    const { selectedEventsFrameIdx } = useContext(EatEventSelectionContext);
    const { uniqueEventTypes, setEatEvents } = useContext(EatEventsContext);

    const onSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const targetEventType = Number(event.target.value);
        if (targetEventType < 0) return;

        setEatEvents((prevState) =>
            prevState.map((eatEvent) =>
                selectedEventsFrameIdx?.includes(eatEvent.idxFrame)
                    ? { ...eatEvent, eventType: targetEventType }
                    : eatEvent
            )
        );
    };

    if (selectedEventsFrameIdx !== undefined) {
        return (
            <EventTypeChangePanelContainer>
                <table>
                    <tbody>
                        <tr>
                            <td>Selected events:&nbsp;</td>
                            <td>{selectedEventsFrameIdx.length}</td>
                        </tr>
                        <tr>
                            <td>Target event type:&nbsp;</td>
                            <td>
                                <Select
                                    id="target-event-type-select"
                                    value={-1}
                                    label="Frame Rate (FPS)"
                                    onChange={onSelectChange}
                                >
                                    <MenuItem key={-1} value={-1}>
                                        &nbsp;
                                    </MenuItem>
                                    {uniqueEventTypes.sort().map((eventType) => (
                                        <MenuItem key={eventType} value={eventType}>
                                            {eventType}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </EventTypeChangePanelContainer>
        );
    }

    return null;
};

export default EventTypeChangePanel;
