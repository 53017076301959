import { EatEvent } from 'types/EatEvent';
import { EatEventSequence } from 'types/EatEventSequence';
import _ from 'lodash';

export function aggregateEvents(eatEvents: EatEvent[], minSequenceLength?: number): EatEventSequence[] {
    if (eatEvents.length === 0) return [];

    // Find sequences of consecutive eatEvents with the same eventType.
    // The sequences are stored in an array of EatEventSequence.
    let eatEventSequences: EatEventSequence[] = [];

    let idxStartFrame = 0;
    let eventType = eatEvents[0].eventType;

    // Iterate over the eatEvents.
    for (const eatEvent of eatEvents) {
        // If the eventType is the same as the previous eventType, and we are not at the end of the eatEvents array,
        // then we are in the same sequence. Continue.
        if (eatEvent.eventType === eventType && eatEvent.idxFrame < eatEvents.length - 1) continue;

        const idxEndFrame = eatEvent.idxFrame === eatEvents.length - 1 ? eatEvent.idxFrame : eatEvent.idxFrame - 1;

        eatEventSequences.push({
            idxStartFrame,
            idxEndFrame,
            avgEventsProbability: averageEventsProbabilities(eatEvents.slice(idxStartFrame, idxEndFrame + 1)),
            eventType,
        });

        idxStartFrame = eatEvent.idxFrame;
        eventType = eatEvent.eventType;
    }

    if (minSequenceLength !== undefined) {
        // Filter out the eatEventSequences that are shorter than minSequenceLength.
        eatEventSequences = eatEventSequences.filter(
            (eatEventSequence) => eatEventSequence.idxEndFrame - eatEventSequence.idxStartFrame + 1 >= minSequenceLength
        );
    }

    return eatEventSequences;
}

function averageEventsProbabilities(eatEvents: EatEvent[]): number[] {
    if (eatEvents.length === 0) return [];

    // Create an array containing all zeros with the length of the eventsProbability array.
    const initValue = _.times(eatEvents[0].eventsProbability.length, _.constant(0));

    // Sum all the eventsProbability arrays of the eatEvents.
    const eventsProbabilitySum = eatEvents.reduce((acc, eatEvent) => {
        return acc.map((value, idx) => value + eatEvent.eventsProbability[idx]);
    }, initValue);

    // Divide eventsProbabilitySum by the number of eatEvents to get the average.
    return eventsProbabilitySum.map((value) => value / eatEvents.length);
}
