import { ScaleOrdinal } from 'd3-scale';
import React, { Dispatch, SetStateAction } from 'react';
import { EatEvent } from 'types/EatEvent';
import { EatEventSequence } from 'types/EatEventSequence';
import { scaleOrdinal } from '@visx/scale';

interface IEatEventsContext {
    eatEvents: EatEvent[];
    setEatEvents: Dispatch<SetStateAction<EatEvent[]>>;
    eatEventSequences: EatEventSequence[];
    uniqueEventTypes: EatEvent['eventType'][];
    eventTypeColorScale: ScaleOrdinal<EatEvent['eventType'], string>;
    eventTypeColorScaleLighter: ScaleOrdinal<EatEvent['eventType'], string>;
    eventTypeColorScaleLightest: ScaleOrdinal<EatEvent['eventType'], string>;
    eventTypeColorScaleDark: ScaleOrdinal<EatEvent['eventType'], string>;
}

const defaultContext: IEatEventsContext = {
    eatEvents: [],
    setEatEvents: () => {
        // Initial value. Replaced by context provider.
    },
    eatEventSequences: [],
    uniqueEventTypes: [],
    eventTypeColorScale: scaleOrdinal<EatEvent['eventType'], string>(),
    eventTypeColorScaleLighter: scaleOrdinal<EatEvent['eventType'], string>(),
    eventTypeColorScaleLightest: scaleOrdinal<EatEvent['eventType'], string>(),
    eventTypeColorScaleDark: scaleOrdinal<EatEvent['eventType'], string>(),
};

const EatEventsContext = React.createContext(defaultContext);

export default EatEventsContext;
