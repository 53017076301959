import React from 'react';
import { VIDEO_FILE_INFO_DEFAULT, VideoFileInfo } from 'types/VideoFileInfo';

interface IVideoFileContext {
    videoFileInfo: VideoFileInfo;
}

const defaultContext: IVideoFileContext = {
    videoFileInfo: VIDEO_FILE_INFO_DEFAULT,
};

const VideoFileContext = React.createContext(defaultContext);

export default VideoFileContext;
