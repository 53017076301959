import React from 'react';
import { ParentSize } from '@visx/responsive';
import EditorPanelSVG from 'App/EditorPanel/EditorPanelSVG';
import { Grid } from '@material-ui/core';
import EventTypeChangePanel from 'App/EditorPanel/EventTypeChangePanel';

interface Props {}

const EditorPanel: React.FunctionComponent<Props> = ({}: Props) => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <EventTypeChangePanel />
                <ParentSize>{({ width }) => <EditorPanelSVG width={width} height={200} />}</ParentSize>
            </Grid>
        </Grid>
    );
};

export default EditorPanel;
