import React, { useCallback, useContext, useState } from 'react';
import EventFileManager from 'App/FileManager/EventFileManager';
import VideoFileContextProvider from 'App/VideoFileContextProvider';
import { AppBar, Container, Grid, IconButton, makeStyles, Paper, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import EatEventsContextProvider from 'App/EatEventsContextProvider';
import VideoFileManager from 'App/FileManager/VideoFileManager';
import VideoStateContextProvider from 'App/VideoStateContextProvider';
import VideoSeekContextProvider from 'App/VideoSeekContextProvider';
import { useKey } from 'react-use';
import VideoStateContext from 'App/VideoStateContext';
import VideoSeekContext from 'App/VideoSeekContext';
import EditorPanel from 'App/EditorPanel/EditorPanel';
import VideoPlayer from 'App/VideoPlayer/VideoPlayer';
import { VideoFileInfo } from 'types/VideoFileInfo';
import EventTable from 'App/EventTable';
import EatEventSelectionContextProvider from 'App/EatEventSelectionContextProvider';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    mainContainer: {
        padding: theme.spacing(2),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

function App() {
    const classes = useStyles();

    const [videoFileInfo, setVideoFileInfo] = useState<VideoFileInfo>();

    const onVideoFileInfoChangeHandler = useCallback((videoFileInfo: VideoFileInfo) => {
        setVideoFileInfo(videoFileInfo);
    }, []);

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        <strong>Collective Eating</strong> - YOLO Event Editor
                    </Typography>
                </Toolbar>
            </AppBar>
            <Container className={classes.mainContainer}>
                <EatEventsContextProvider>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <VideoFileManager onVideoFileInfoChange={onVideoFileInfoChangeHandler} />
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <EventFileManager />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={9}>
                            <Paper className={classes.paper}>
                                {videoFileInfo && (
                                    <VideoFileContextProvider videoFileInfo={videoFileInfo}>
                                        <EatEventSelectionContextProvider>
                                            <VideoStateContextProvider>
                                                <VideoSeekContextProvider>
                                                    <AppContent />
                                                </VideoSeekContextProvider>
                                            </VideoStateContextProvider>
                                        </EatEventSelectionContextProvider>
                                    </VideoFileContextProvider>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </EatEventsContextProvider>
            </Container>
        </>
    );
}

const AppContent: React.FunctionComponent = () => {
    const { toggleIsPlaying } = useContext(VideoStateContext);
    const { seekDelta } = useContext(VideoSeekContext);

    const classes = useStyles();

    useKey('ArrowRight', (e: KeyboardEvent) => {
        const increment = e.shiftKey ? 1 : 0.1;
        seekDelta(increment);
    });
    useKey('ArrowLeft', (e: KeyboardEvent) => {
        const increment = e.shiftKey ? -1 : -0.1;
        seekDelta(increment);
    });
    useKey(' ', (e: KeyboardEvent) => {
        toggleIsPlaying();
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={8}>
                <Paper className={classes.paper}>
                    <VideoPlayer />
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper className={classes.paper}>
                    <EventTable />
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <EditorPanel />
                </Paper>
            </Grid>
        </Grid>
    );
};

export default App;
