import React, { useContext } from 'react';
import { Group } from '@visx/group';
import { EatEventSequence } from 'types/EatEventSequence';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import EatEventSelectionContext from 'App/EatEventSelectionContext';

interface Props {
    eatEventSequence: EatEventSequence;
    x: number;
    y: number;
    width: number;
    height: number;
    fill?: string;
    stroke?: string;
}

const EventSequenceComponent: React.FunctionComponent<Props> = ({
    eatEventSequence,
    x,
    y,
    width,
    height,
    fill,
    stroke,
}: Props) => {
    const { setSelectedEventSequence } = useContext(EatEventSelectionContext);

    const { tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip();

    const { TooltipInPortal } = useTooltipInPortal();

    const handleMouseMove = (event: React.MouseEvent<SVGRectElement>) => {
        showTooltip({
            tooltipLeft: event.clientX,
            tooltipTop: event.clientY,
        });
    };

    const handleClick = (event: React.MouseEvent<SVGRectElement>) => {
        setSelectedEventSequence(eatEventSequence);
    };

    return (
        <Group>
            <rect
                onMouseMove={handleMouseMove}
                onMouseOut={hideTooltip}
                onClick={handleClick}
                fill={fill}
                stroke={stroke}
                x={x}
                width={width}
                y={y}
                height={height}
                rx={5}
            />

            {tooltipOpen && (
                <TooltipInPortal top={tooltipTop} left={tooltipLeft}>
                    Frames: <strong>{eatEventSequence.idxStartFrame}</strong> to{' '}
                    <strong>{eatEventSequence.idxEndFrame}</strong>
                    <br />
                    Event Type: <strong>{eatEventSequence.eventType}</strong>
                    <br />
                    Avg Probabilities:{' '}
                    <strong>[{eatEventSequence.avgEventsProbability.map((p) => p.toFixed(2)).join(', ')}]</strong>
                </TooltipInPortal>
            )}
        </Group>
    );
};

export default EventSequenceComponent;
