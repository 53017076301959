import React from 'react';
import { ScaleLinear } from 'd3-scale';

interface Props {
    maxVideoDuration: number;
    timeScale: ScaleLinear<number, number>;
}

const OutOfRangeMarkers: React.FunctionComponent<Props> = ({ maxVideoDuration, timeScale }: Props) => {
    const timeScaleRange = timeScale.range();

    const rangeBeforeVideos = timeScale(0) - timeScaleRange[0];
    const rangeAfterVideos = timeScaleRange[1] - timeScale(maxVideoDuration);

    return (
        <>
            {rangeBeforeVideos > 0 && (
                <rect
                    x={timeScaleRange[0]}
                    y={0}
                    width={rangeBeforeVideos}
                    height={400}
                    fill={'#123e59'}
                    fillOpacity={0.1}
                />
            )}
            {rangeAfterVideos > 0 && (
                <rect
                    x={timeScale(maxVideoDuration)}
                    y={0}
                    width={rangeAfterVideos}
                    height={400}
                    fill={'#123e59'}
                    fillOpacity={0.1}
                />
            )}
        </>
    );
};

export default OutOfRangeMarkers;
