import React from 'react';
import { EatEventSequence } from 'types/EatEventSequence';

interface IEatEventSelectionContext {
    selectedEventsFrameIdx: number[] | undefined;
    setSelectedEventsFrameIdx: (eventsFrameIdx: number[] | undefined) => void;
    selectedEventSequence: EatEventSequence | undefined;
    setSelectedEventSequence: (event: EatEventSequence | undefined) => void;
}

const defaultContext: IEatEventSelectionContext = {
    selectedEventsFrameIdx: undefined,
    setSelectedEventsFrameIdx: () => {
        // Initial value. Replaced by context provider.
    },
    selectedEventSequence: undefined,
    setSelectedEventSequence: () => {
        // Initial value. Replaced by context provider.
    },
};

const EatEventSelectionContext = React.createContext(defaultContext);

export default EatEventSelectionContext;
