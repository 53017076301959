import React, { useMemo } from 'react';
import EatEventsContext from 'App/EatEventsContext';
import { EatEvent } from 'types/EatEvent';
import { aggregateEvents } from 'lib/event-processing';
import _ from 'lodash';
import { scaleOrdinal } from '@visx/scale';
import { schemeTableau10 } from 'd3-scale-chromatic';
import { darkest, lighter, lightest, mid } from 'lib/colors';

interface Props {}

const EatEventsContextProvider = ({ children }: React.PropsWithChildren<Props>) => {
    const [eatEvents, setEatEvents] = React.useState<EatEvent[]>([]);
    const eatEventSequences = useMemo(() => aggregateEvents(eatEvents, 30), [eatEvents]);

    const uniqueEventTypes = useMemo(
        () => _.uniq(eatEventSequences.map((eatEventSequence) => eatEventSequence.eventType)),
        [eatEventSequences]
    );

    const eventTypeColorScale = useMemo(
        () =>
            scaleOrdinal<EatEvent['eventType'], string>({
                range: [...schemeTableau10.map(mid)],
                domain: uniqueEventTypes,
            }),
        [uniqueEventTypes]
    );

    const eventTypeColorScaleDark = useMemo(
        () =>
            scaleOrdinal<EatEvent['eventType'], string>({
                range: [...schemeTableau10.map(darkest)],
                domain: uniqueEventTypes,
            }),
        [uniqueEventTypes]
    );

    const eventTypeColorScaleLightest = useMemo(
        () =>
            scaleOrdinal<EatEvent['eventType'], string>({
                range: [...schemeTableau10.map(lightest)],
                domain: uniqueEventTypes,
            }),
        [uniqueEventTypes]
    );

    const eventTypeColorScaleLighter = useMemo(
        () =>
            scaleOrdinal<EatEvent['eventType'], string>({
                range: [...schemeTableau10.map(lighter)],
                domain: uniqueEventTypes,
            }),
        [uniqueEventTypes]
    );

    return (
        <EatEventsContext.Provider
            value={{
                eatEvents,
                setEatEvents,
                eatEventSequences,
                uniqueEventTypes,
                eventTypeColorScale,
                eventTypeColorScaleLighter,
                eventTypeColorScaleLightest,
                eventTypeColorScaleDark,
            }}
        >
            {children}
        </EatEventsContext.Provider>
    );
};

export default EatEventsContextProvider;
