import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from 'App/App';

if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
        exclude: [/^Portal/, /^withBoundingRects/],
    });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);
