/* eslint-disable @typescript-eslint/no-unused-vars */
import { err, JsonDecoder, ok } from 'ts.data.json';
import { $JsonDecoderErrors } from 'ts.data.json/dist/json-decoder';
import { v4 as uuidv4 } from 'uuid';

/** **********************
 * Useful Array Decoders *
 *********************** */
export const stringArrayDecoder = JsonDecoder.array<string>(JsonDecoder.string, 'string[]');
export const numberArrayDecoder = JsonDecoder.array<number>(JsonDecoder.number, 'number[]');

export const stringOrNumberDecoder = JsonDecoder.oneOf<string | number>(
    [JsonDecoder.string, JsonDecoder.number],
    'string | number'
);

export const stringOrNumberArrayDecoder = JsonDecoder.array<string | number>(
    stringOrNumberDecoder,
    'Array<string|number>[]'
);

/** *****
 * Date *
 ****** */
const dateDecoder: JsonDecoder.Decoder<Date> = new JsonDecoder.Decoder<Date>((json: unknown) => {
    if (typeof json === 'string') {
        const isValidDate = !isNaN(Date.parse(json));

        if (isValidDate) {
            return ok<Date>(new Date(json));
        }
    }

    return err<Date>($JsonDecoderErrors.primitiveError(json, 'Date'));
});

/** *****
 * UUID *
 ****** */
const uuidFailoverDecoder: JsonDecoder.Decoder<string> = new JsonDecoder.Decoder<string>((json: unknown) => {
    if (json === undefined) {
        return ok<string>(uuidv4());
    }

    if (typeof json === 'string') {
        // Verify that json string is a valid UUID.
        const isUuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(json);

        if (isUuid) {
            return ok<string>(json);
        }
    }

    return err<string>($JsonDecoderErrors.primitiveError(json, 'UUID'));
});

/** **********
 * EatEvents *
 *********** */

//
// interface RawEatEvent {
//     idx_frame: number;
//     events_prob: number[];
//     event_type: number;
// }
//
// const eatEventDecoder = JsonDecoder.object<RawEatEvent>(
//     {
//         idx_frame: JsonDecoder.number,
//         events_prob: numberArrayDecoder,
//         event_type: JsonDecoder.number,
//     },
//     'EatEvent'
// ).map((eatEvent): EatEvent => {
//     return {
//         idx_frame: eatEvent.idx_frame,
//         events_prob: eatEvent.events_prob,
//         event_type: eatEvent.event_type,
//     };
// });
//
// export const eatEventArrayDecoder = JsonDecoder.array<EatEvent>(eatEventDecoder, 'EatEvent[]');
