import { ScaleLinear } from 'd3-scale';
import { useMemo } from 'react';
import { LodFactor } from 'types/LodFactor';

export function useFrameIdxLodFactor(frameScale: ScaleLinear<number, number>) {
    const frameDomain = useMemo(() => frameScale.domain(), [frameScale]);

    const frameDelta = frameDomain[1] - frameDomain[0];

    if (frameDelta > 4800) {
        return LodFactor.SIXTEENTH;
    }
    if (frameDelta > 2400) {
        return LodFactor.EIGHTH;
    }
    if (frameDelta > 1200) {
        return LodFactor.QUARTER;
    }
    if (frameDelta > 600) {
        return LodFactor.HALF;
    }

    return LodFactor.ORIGINAL;
}
